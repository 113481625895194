if (document.querySelector('.product-single')) {
    handleMobileNavigationDropdown();
    handleTableScroll();
    handleTableTabs();
    productSingleCarousels();
    handleImageGalleryPosition();
    handleVideoPlayer();
    handleModalGallery();

    window.addEventListener('resize', handleImageGalleryPosition);
}

function handleMobileNavigationDropdown() {
    var toggleButton = document.querySelector('.product-single__nav-toggle');
    var parent = toggleButton.closest('.product-single__nav--mobile');
    var countElement = parent.querySelector('.product-single__nav-count--active');
    var textElement = parent.querySelector('.product-single__nav-link');
    var dropdown = parent.querySelector('.product-single__nav-dropdown');
    var dropdownLinks = dropdown.querySelectorAll('.product-single__nav-link');

    toggleButton.addEventListener('click', function() {
        parent.classList.toggle('active');
    });

    for (var i = 0; i < dropdownLinks.length; i++) {
        var link = dropdownLinks[i];
        
        (function(index, link) {
            link.addEventListener('click', function() {
                countElement.textContent = index + 1;
                textElement.textContent = link.textContent;
                parent.classList.remove('active');
            });
        })(i, link);
    }
}

function handleTableScroll() {
    document.addEventListener('click', function (event) {
        var button = event.target.closest('.product-single__table-nav-prev');

        if (button) {
            scrollTable(event.target, 'left');
            return;
        }

        button = event.target.closest('.product-single__table-nav-next');

        if (button) {
            scrollTable(event.target, 'right');
            return;
        }
    });

    function scrollTable(button, direction) {
        var scrollAmount = 180;
        var buttonContainer = button.closest('.product-single__table-nav');
        var tableContainer = buttonContainer.closest('.container').querySelector('.product-single__table-tabs-content.active');
        var table = null;

        if (tableContainer) {
            table = tableContainer.querySelector('.product-single__table');
        } else {
            table = buttonContainer.nextElementSibling;
        }

        var currentScroll = table.scrollLeft;
        
        if (direction === 'left') {
            table.scrollLeft = currentScroll - scrollAmount;
        } else if (direction === 'right') {
            table.scrollLeft = currentScroll + scrollAmount;
        }

        updateTableScrollButtonState(table, buttonContainer);
    }

    checkTableScrollability();
}

function updateTableScrollButtonState(table, buttonContainer) {
    var leftButton = buttonContainer.querySelector('.product-single__table-nav-prev');
    var rightButton = buttonContainer.querySelector('.product-single__table-nav-next');

    if (table.scrollLeft <= 0) {
        leftButton.disabled = true;
    } else {
        leftButton.disabled = false;
    }

    if (table.scrollLeft + table.clientWidth >= table.scrollWidth) {
        rightButton.disabled = true;
    } else {
        rightButton.disabled = false;
    }
}

function checkTableScrollability() {
    var tables = document.querySelectorAll('.product-single__table');

    for (var i = 0; i < tables.length; i++) {
        var table = tables[i];
        var buttonContainer = table.closest('.container').querySelector('.product-single__table-nav');

        if (table.scrollWidth == 0 && table.clientWidth == 0) {
            continue;
        }

        if (table.scrollWidth > table.clientWidth) {
            buttonContainer.style.display = 'flex';
        } else {
            buttonContainer.style.display = 'none';
        }
    }
}

function handleTableTabs() {
    var tabButtons = document.querySelectorAll('.product-single__table-tabs-button');
    var toggleButton = document.querySelector('.product-single__table-tabs-toggle');

    if (toggleButton) {
        var parent = toggleButton.closest('.product-single__table-tabs-nav--mobile');
        var countElement = parent.querySelector('.product-single__table-tabs-count--active');
        var textElement = parent.querySelector('.product-single__table-tabs-text');
        var dropdown = parent.querySelector('.product-single__table-tabs-dropdown');

        toggleButton.addEventListener('click', function() {
            parent.classList.toggle('active');
        });
    }

    for (var i = 0; i < tabButtons.length; i++) {
        var button = tabButtons[i];
        
        (function(index, button) {
            button.addEventListener('click', function(e) {
                var currentActiveButton = document.querySelector('.product-single__table-tabs-button.active');
                var currentActiveTab = document.querySelector('.product-single__table-tabs-content.active');
                var activeIndex = e.target.getAttribute('data-table-tab-button');
                var newActiveTab = document.querySelector('.product-single__table-tabs-content[data-table-tab="' + activeIndex + '"]');

                currentActiveButton.classList.remove('active');
                currentActiveTab.classList.remove('active');
                
                e.target.classList.add('active');
                newActiveTab.classList.add('active');

                if (toggleButton) {
                    countElement.textContent = index + 1;
                    textElement.textContent = button.textContent;
                    parent.classList.remove('active');
                }

                updateTableScrollButtonState(newActiveTab.querySelector('.product-single__table'), newActiveTab.closest('.container').querySelector('.product-single__table-nav'));
                checkTableScrollability();
            });
        })(i, button);
    }
}

function productSingleCarousels() {
    var productAccessories = new Swiper('.product-single__accessories-carousel', {
        loop: false,
        slidesPerView: 4,
        spaceBetween: 16,
        grabCursor: true,
        watchOverflow: true,
        navigation: {
            nextEl: '.product-single__accessories-next',
            prevEl: '.product-single__accessories-prev',
        },
        pagination: {
            el: '.product-single__accessories-bullets',
            clickable: true,
            dynamicBullets: true,
        },
        breakpoints: {
            992: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 2,
            },
            576: {
                slidesPerView: 1.3,
            },
            0: {
                slidesPerView: 1.3,
            }
        },
        on: {
            init: function () {
                handleCarouselNavigation(this);
            },
            resize: function () {
                handleCarouselNavigation(this);
            },
        }
    });

    var productResources = new Swiper('.product-single__resources-carousel', {
        loop: false,
        slidesPerView: 4,
        spaceBetween: 16,
        grabCursor: true,
        watchOverflow: true,
        navigation: {
            nextEl: '.product-single__resources-next',
            prevEl: '.product-single__resources-prev',
        },
        pagination: {
            el: '.product-single__resources-bullets',
            clickable: true,
            dynamicBullets: true,
        },
        breakpoints: {
            992: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 2,
            },
            576: {
                slidesPerView: 1.1,
            },
            0: {
                slidesPerView: 1.1,
            }
        },
        on: {
            init: function () {
                handleCarouselNavigation(this);
            },
            resize: function () {
                handleCarouselNavigation(this);
            },
        }
    });

    var productRelated = new Swiper('.product-single__related-carousel', {
        loop: false,
        slidesPerView: 3.3,
        spaceBetween: 16,
        grabCursor: true,
        watchOverflow: true,
        navigation: {
            nextEl: '.product-single__related-next',
            prevEl: '.product-single__related-prev',
        },
        pagination: {
            el: '.product-single__related-bullets',
            clickable: true,
            dynamicBullets: true,
        },
        breakpoints: {
            992: {
                slidesPerView: 2.2,
            },
            768: {
                slidesPerView: 2.2,
            },
            576: {
                slidesPerView: 1.3,
            },
            0: {
                slidesPerView: 1.3,
            }
        },
        on: {
            init: function () {
                handleCarouselNavigation(this);
            },
            resize: function () {
                handleCarouselNavigation(this);
            },
        }
    });

    function handleCarouselNavigation(swiperInstance) {
        var totalSlides = swiperInstance.slides.length;
        var slidesPerView = swiperInstance.params.slidesPerView;
        var carouselElm = swiperInstance.$el[0].parentElement;
        var navigationPrev = carouselElm.querySelector('.swiper-button-prev');
        var navigationNext = carouselElm.querySelector('.swiper-button-next')
        var pagination = carouselElm.querySelector('.swiper-pagination-bullets');

        if (totalSlides <= slidesPerView) {
            navigationPrev.classList.add('d-none');
            navigationNext.classList.add('d-none');
            pagination.classList.add('d-none');
        } else {
            navigationPrev.classList.remove('d-none');
            navigationNext.classList.remove('d-none');
            pagination.classList.remove('d-none');
        }
    }

    var productGalleryCarousel = new Swiper('.js_product-gallery-carousel', {
        loop: false,
        direction: 'vertical',
        navigation: {
            nextEl: '.js_product-gallery-carousel-next',
            prevEl: '.js_product-gallery-carousel-prev',
        },
        // slideActiveClass: 'swiper-slide-active js_product-carousel-active-slide',
        breakpoints: {
            768: {
                direction: 'horizontal',
            },
        },
    });

    var productGalleryCarouselThumbs = new Swiper('.js_product-gallery-carousel-thumbs', {
        loop: false,
        spaceBetween: 10,
        slidesPerView: 6,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        centeredSlides: true,
        direction: 'vertical',
        breakpoints: {
            768: {
                direction: 'horizontal',
                slidesPerView: 4.2,
            },
        },
    });

    if (productGalleryCarousel && productGalleryCarousel.hasOwnProperty('controller')) {
        productGalleryCarousel.controller.control = productGalleryCarouselThumbs;
    }

    if (productGalleryCarouselThumbs && productGalleryCarouselThumbs.hasOwnProperty('controller')) {
        productGalleryCarouselThumbs.controller.control = productGalleryCarousel;
    }

    var productModalGalleryCarousel = new Swiper('.js_product-modal-gallery-carousel', {
        loop: false,
        slidesPerView: 1.9,
        spaceBetween: 15,
        centeredSlides: true,
        navigation: {
            nextEl: '.js_product-modal-gallery-carousel-next',
            prevEl: '.js_product-modal-gallery-carousel-prev',
        },
        // slideActiveClass: 'swiper-slide-active js_product-carousel-active-slide',
        on: {
            slideChangeTransitionEnd: function() {
                var fullImageContainer = document.querySelector('.product-single__gallery-image-full');
                
                if (fullImageContainer.classList.contains('active')) {
                    var carousel = document.querySelector('.js_product-modal-gallery-carousel');
                    var activeImage = carousel.querySelector('.swiper-slide-active picture');
                    var fullImage = activeImage.cloneNode(true);

                    fullImageContainer.innerHTML = '';
                    fullImageContainer.appendChild(fullImage);
                }
            },
        },
    });

    var productModalGalleryCarouselThumbs = new Swiper('.js_product-modal-gallery-carousel-thumbs', {
        loop: false,
        spaceBetween: 10,
        slidesPerView: 5,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        centeredSlides: true,
        breakpoints: {
            768: {
                slidesPerView: 4,
            },
        },
    });

    if (productModalGalleryCarousel && productModalGalleryCarousel.hasOwnProperty('controller')) {
        productModalGalleryCarousel.controller.control = productModalGalleryCarouselThumbs;
    }

    if (productModalGalleryCarouselThumbs && productModalGalleryCarouselThumbs.hasOwnProperty('controller')) {
        productModalGalleryCarouselThumbs.controller.control = productModalGalleryCarousel;
    }
}

function handleImageGalleryPosition() {
    if (window.innerWidth < 768) {
        var carousel = document.querySelector('.product-single__carousel-wrapper .product-single__gallery');
        var parent = document.querySelector('.product-single__carousel-wrapper--mobile');

        if (carousel) {
            parent.appendChild(carousel);
        }
    } else {
        var carousel = document.querySelector('.product-single__carousel-wrapper--mobile .product-single__gallery');
        var parent = document.querySelector('.product-single__carousel-wrapper');

        if (carousel) {
            parent.appendChild(carousel);
        }
    }
}

function handleVideoPlayer() {

    document.addEventListener('click', function(event){
        if(event.target && event.target.matches('.js_video-open, .js_video-open *')) {
  
            event.preventDefault();
    
            var videoPlayerContainer = document.querySelector('.js_video-wrapper');
            var overlay = document.querySelector('.js_product-page-video-overlay');
            var videoPlayer = videoPlayerContainer.querySelector('.js_product-page-video-player');
            var closeVideo = videoPlayerContainer.querySelector('.js_close-video');
            var videoText = videoPlayerContainer.querySelector('.js_product-page-video-text');
    
            function pauseVideo() {
                videoPlayer.setAttribute('src', videoPlayer.getAttribute('src'));
            };
    
            function closeVideoPlayer() {
                videoPlayerContainer.classList.remove('visible');
                overlay.classList.remove('visible');
                pauseVideo();
            };
    
            function openVideoPlayer() {
                var parentElm = event.target;
                while (parentElm.querySelector('.js_video-open') === null) {
                    parentElm = parentElm.parentElement;
                }
                var linkToAssign = parentElm.querySelector('[data-video-url]').getAttribute('data-video-url');
                var titleToAssign = parentElm.querySelector('[data-video-title]').getAttribute('data-video-title');
                var descriptionToAssign = parentElm.querySelector('[data-video-description]').getAttribute('data-video-description');
                videoPlayer.setAttribute('src', linkToAssign);
                videoText.querySelector('span').innerHTML = titleToAssign;
                videoText.querySelector('strong').innerHTML = descriptionToAssign;
                videoPlayerContainer.classList.add('visible');
                overlay.classList.add('visible');
            };
  
            openVideoPlayer();
            
            overlay.addEventListener('click', function() {
                closeVideoPlayer();
            });
    
            closeVideo.addEventListener('click', function() {
                closeVideoPlayer();
            });
        };
    });
};

function handleModalGallery() {

    document.addEventListener('click', function(event){
        if(event.target && event.target.matches('.js_open-gallery, .js_open-gallery *')) {
  
            event.preventDefault();
    
            var galleryContainer = document.querySelector('.js_product-modal-gallery');
            var closeGalleryButton = galleryContainer.querySelector('.js_close-gallery');
    
            function closeGallery() {
                var zoomButton = document.querySelector('.js_zoom-gallery');
                
                galleryContainer.classList.remove('visible');

                if (zoomButton.classList.contains('active')) {
                    zoomButton.click();
                }
            };
    
            function openGallery() {
                var swiperInstance = document.querySelector('.js_product-modal-gallery-carousel').swiper;
                var openedImage = event.target.closest('.js_open-gallery');
                var openedImageId = openedImage ? openedImage.getAttribute('data-imageid') : 0;
                
                swiperInstance.slideTo(openedImageId);
                galleryContainer.classList.add('visible');
            };
  
            openGallery();
    
            closeGalleryButton.addEventListener('click', function() {
                closeGallery();
            });
        };
    });

    document.addEventListener('click', function(event){
        if(event.target && event.target.matches('.js_zoom-gallery, .js_zoom-gallery *')) {
  
            event.preventDefault();

            var fullImageContainer = document.querySelector('.product-single__gallery-image-full');
            var zoomButton = document.querySelector('.js_zoom-gallery');
            var thumbsNav = document.querySelector('.product-single__modal-gallery .product-single__gallery-nav');
            var carousel = document.querySelector('.js_product-modal-gallery-carousel');

            if (fullImageContainer.classList.contains('active')) {
                fullImageContainer.innerHTML = '';
                fullImageContainer.classList.remove('active');
                zoomButton.classList.remove('active');
                thumbsNav.classList.remove('invisible');
                carousel.querySelector('.swiper-wrapper').classList.remove('invisible');
            } else {
                var activeImage = carousel.querySelector('.swiper-slide-active picture');
                var fullImage = activeImage.cloneNode(true);

                fullImageContainer.innerHTML = '';
                fullImageContainer.appendChild(fullImage);
                fullImageContainer.classList.add('active');
                zoomButton.classList.add('active');
                thumbsNav.classList.add('invisible');
                carousel.querySelector('.swiper-wrapper').classList.add('invisible');
            }
        };
    });
};
